var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"prog-wrap2"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"barOverflow"},[_c('div',{staticClass:"bar",style:({
            transform: 'rotate(' + _vm.rotateCircleEstimate + 'deg)',
          })})]),_c('span',{staticClass:"pace2 light-grey"},[_vm._v(_vm._s(_vm.displayPacingEstimate))]),_c('div',{staticClass:"pace-dif light-grey",class:_vm.showDiff ? 'show-dif' : '',style:({
          color: _vm.paceDescColor,
        })},[_vm._v(" "+_vm._s(`${_vm.paceDiff ? `${Math.abs(_vm.paceDiff)}%` : 'On Pace'}`)+" "+_vm._s(_vm.paceDiffDesc)+" ")])])]),_c('div',{staticClass:"prog-wrap"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"barOverflow"},[_c('div',{staticClass:"bar",style:({
            transform: 'rotate(' + _vm.rotateCircle + 'deg)',
            borderBottomColor: _vm.barColor,
            borderRightColor: _vm.barColor,
          })})]),_c('div',{staticClass:"pace"},[_c('span',{staticClass:"light-grey"},[_vm._v(_vm._s(_vm.displayPacing))]),_c('div',{staticClass:"light-grey",staticStyle:{"display":"inline-block"}},[_vm._v("%")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }