<template>
  <div class="checkbox-select">
    <div v-if="loading" class="txt-center spinner-border">
      <b-loading-spinner2 :width="20" class="spinner-style" />
    </div>
    <div
      v-else
      class="checkbox-select__trigger"
      :class="{ isActive: activeTrigger }"
      @click="showDropdown"
      @blur="showDropdown"
    >
      <span class="checkbox-select__title">{{
        checkedFilters.length ? `${checkedFilters.length} filters selected` : 'Search...'
      }}</span>
      <!-- <i class="fas fa-angle-down"></i> -->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
        <path
          d="M121.3 34.6c-1.6-1.6-4.2-1.6-5.8 0l-51 51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l53.9 53.9c.8.8 1.8 1.2 2.9 1.2 1 0 2.1-.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2.1-5.8z"
        />
      </svg>
    </div>
    <div
      v-if="dropdown"
      id="dropdown"
      class="checkbox-select__dropdown"
      :class="{ activeSearch: showLoader }"
    >
      <div class="checkbox-select__search-wrapp">
        <input
          v-model="search"
          type="text"
          placeholder="search filters..."
          @focus="showLoader = true"
          @blur="showLoader = false"
        />
      </div>
      <div class="checkbox-select__col">
        <div :selectAll="selectAll" class="checkbox-select__select-all">
          <label for="selectAll">{{ selectAllText }}</label>
          <input id="selectAll" v-model="allSelected" type="checkbox" @click="selectAll" />
        </div>
        <div :selectFiltered="selectFiltered" class="checkbox-select__select-filtered">
          <label for="selectFiltered">{{ selectFilteredText }}</label>
          <input
            id="selectFiltered"
            v-model="filterSelected"
            type="checkbox"
            @click="selectFiltered"
          />
        </div>

        <div class="checkbox-select__info">
          {{ checkedFilters.length }} / {{ filters.length }} SELECTED
        </div>
      </div>
      <ul id="customScroll" class="checkbox-select__filters-wrapp" data-simplebar-auto-hide="false">
        <li v-for="(filter, index) in filteredList" :key="index">
          <div class="checkbox-select__check-wrapp">
            <input
              :id="index"
              v-model="checkedFilters"
              class="conditions-check"
              :value="filter"
              type="checkbox"
            />
            <!-- <font-awesome-icon class="itemCheckbox unchecked" :icon="['fas', 'check']" /> -->
            <label :for="index">{{ filter.value }}</label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import elementsMixin from '../mixins/element-mixin';
import validationsMixin from '../mixins/validation-mixin';
// import bLoadingSpinner from './b-loading-spinner.vue';
import bLoadingSpinner2 from './b-loading-spinner2.vue';

export default {
  components: { bLoadingSpinner2 },
  mixins: [validationsMixin, elementsMixin],

  props: {
    name: {
      required: false,
      type: String,
      default: null,
    },
    value: {
      required: false,
      type: [String, Number, Array, Object, Boolean],
      default: null,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
    options: {
      required: true,
      type: Array,
    },
    defaultEmpty: {
      required: false,
      type: Boolean,
      default: true,
    },
    searchable: {
      required: false,
      type: Boolean,
      default: false,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    originalComponent: {
      required: false,
      type: String,
      default: 'autoComplete',
    },
    // prop name used for displaying option text when options is an array of
    // objects
    labelKey: {
      required: false,
      type: String,
      default: 'name',
    },
    // When set, use this key for overriding the text which is displayed for the selected option.
    labelSelectedKey: {
      required: false,
      type: String,
      default: null,
    },
    // prop name used for setting/emitting the selected value when options is an
    // array of objects
    idKey: {
      required: false,
      type: String,
      default: 'id',
    },
    // prop name used for field containing nested options when options is an
    // array of objects.
    optsKey: {
      required: false,
      type: String,
      default: 'opts',
    },
    clearSearchOnSelect: {
      required: false,
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      required: false,
      type: Boolean,
      default: false,
    },
    showClearButton: {
      required: false,
      type: Boolean,
      default: true,
    },
    showClearOption: {
      required: false,
      type: Boolean,
      default: true,
    },
    autofocus: {
      required: false,
      type: Boolean,
      default: undefined,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    showClearSelectionBtn: {
      required: false,
      type: Boolean,
      default: false,
    },
    create: {
      required: false,
      type: Boolean,
      default: false,
    },
    nonSelectableOptions: {
      required: false,
      type: Array,
      default: () => [],
    },
    className: {
      required: true,
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      search: '',
      checkedFilters: [],
      allSelected: false,
      filterSelected: false,
      selectAllText: 'Select All',
      selectFilteredText: 'Select Filtered',
      activeTrigger: false,
      dropdown: false,
      showLoader: false,
      customScroll: '',
    };
  },
  computed: {
    filters() {
      return this.options;
    },
    filteredList() {
      return this.filters.filter((item) => {
        return item.value.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    newValue() {
      return this.value;
    },
  },
  watch: {
    checkedFilters(n, o) {
      if (!_.isEqual(n, o)) {
        // for searchable dropdown
        this.$emit('input', this.checkedFilters);
        this.$emit('change', this.checkedFilters);
        // for regular dropdown
        this.$emit('selected-options', this.checkedFilters, true);
      }
    },
    newValue(n, o) {
      if (!_.isEqual(n, o)) {
        this.loadValues();
      }
    },
  },
  mounted() {
    this.loadValues();
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    selectAll() {
      this.checkedFilters = [];
      this.search = '';
      this.selectAllText = this.selectAllText === 'Select All' ? 'Clear All' : 'Select All';
      if (!this.allSelected) {
        this.filters.forEach((filter) => {
          const existingFilter = this.checkedFilters.find((item) => item.value === filter.value);
          if (existingFilter) {
            return;
          }
          this.checkedFilters.push(filter);
          this.$emit('input', this.checkedFilters);
          this.$emit('change', this.checkedFilters);
        });
      }
    },
    selectFiltered() {
      this.selectFilteredText =
        this.selectFiltered === 'Select Filtered' ? 'Clear Filtered' : 'Select Filtered';
      // if (!this.filterSelected) {
      if (!this.search) return;
      this.filteredList.forEach((filter) => {
        const existingFilter = this.checkedFilters.find((item) => item.value === filter.value);
        if (existingFilter) {
          return;
        }
        this.checkedFilters.push(filter);
      });
      this.$emit('input', this.checkedFilters);
      this.$emit('change', this.checkedFilters);
      // }
    },
    showDropdown() {
      if (this.dropdown === false) {
        this.dropdown = true;
        this.activeTrigger = true;
      } else {
        this.dropdown = false;
        this.activeTrigger = false;
      }
    },
    onClickOutside(e) {
      if (e.target?.classList?.contains('checkbox-select')) {
        return;
      }
      if (!this.$el.contains(e.target)) {
        if (this.dropdown) {
          this.showDropdown();
          this.$emit('dropdown-closed'); // Emit event when dropdown is closed
        }
      }
    },
    loadValues() {
      this.newValue.forEach((filter) => {
        const existingFilter = this.checkedFilters.find((item) => item.value === filter.value);
        if (existingFilter) {
          return;
        }
        this.checkedFilters.push(filter);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
textarea,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
}

.spinner-border {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 5px;
  height: 38px;
}

.spinner-style {
  margin-top: -20px !important;
}

.checkbox-select {
  position: relative;
  max-width: 549px;
  width: 100%;
  color: var(--primarydark2);
  @media only screen and (max-width: 600px) {
    margin: 100px auto 0;
  }
  &__trigger {
    border-radius: 10px;
    background: #fff;
    // background: #fff
    //   url('http://res.cloudinary.com/dnhvfgp9c/image/upload/v1521734636/bcg-pattern.png') repeat;
    position: relative;
    z-index: 1;
    // box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.13);
    // height: 86px;
    height: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 10px 8px 15px;
    border: 1px solid #c5d2d8;
    border-radius: 6px;
    transition: all 0.4s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media only screen and (max-width: 600px) {
      padding: 0 15px;
      height: 70px;
    }
    &.isActive {
      border-radius: 10px 10px 0 0;
      // background: #f2f2f2
      // url('http://res.cloudinary.com/dnhvfgp9c/image/upload/v1521734636/bcg-pattern.png') repeat;
      svg {
        transform: rotate(-180deg) scale(0.5);
      }
    }
    // &:hover {
    //   background: #f4f4f4
    //     url('http://res.cloudinary.com/dnhvfgp9c/image/upload/v1521734636/bcg-pattern.png') repeat;
    // }
    svg {
      width: 20px;
      opacity: 0.6;
      stroke: 4px;
      transform: scale(0.5);
      transition: all 0.4s ease;
      @media only screen and (max-width: 600px) {
        width: 22px;
      }
    }
  }
  &__title {
    font-size: 12px;
    color: var(--primarydark2);
    flex: 1;
    padding-right: 25px;
    letter-spacing: 1px;
    @media only screen and (max-width: 600px) {
      font-size: 15px;
    }
  }
  &__dropdown {
    // opacity: 0;
    // visibility: hidden;
    background: #fff;
    //url('http://res.cloudinary.com/dnhvfgp9c/image/upload/v1521734636/bcg-pattern.png') repeat;
    position: absolute;
    left: 0;
    right: 0;
    // box-shadow: 0 12px 15px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 0 0 8px 8px;
    // overflow: hidden;
    z-index: 9;
    padding-bottom: 2px;
    &:after,
    &:before {
      position: absolute;
      content: '';
      top: 0;
      display: block;
      height: 4px;
      background-color: transparent;
    }
    // &:after {
    //   opacity: 0;
    //   background: #000;
    //   left: -200px;
    //   width: 200px;
    //   background-color: #2980b9;
    //   transition: opacity 0.3s ease;
    //   animation: load 1.8s linear infinite;
    //   background: linear-gradient(
    //     135deg,
    //     rgba(143, 36, 237, 1) 20%,
    //     rgba(143, 36, 237, 1) 20%,
    //     rgba(143, 36, 237, 1) 22%,
    //     rgba(143, 36, 237, 1) 25%,
    //     rgba(16, 124, 179, 1) 100%
    //   );
    // }
    // &:before {
    //   width: 100%;
    //   background-color: #000;
    // }
    &.activeSearch {
      &:after {
        opacity: 1;
      }
    }
    .simplebar-scrollbar {
      width: 3px;
      right: 1px;
    }
  }
  &__search-wrapp {
    padding: 17px 25px 12px;
    @media only screen and (max-width: 600px) {
      padding: 10px 15px 5px;
    }
    input {
      width: 100%;
      height: 30px;
      // height: auto;
      border-width: 0 0 2px;
      border-style: solid;
      border-color: #000;
      font-size: 13px;
      // font-family: 'Roboto Slab', serif;
      font-family: 'Manrope', sans-serif;
      background: transparent;
      background-color: #fff;
      border: 1px solid #c5d2d8;
      padding: 1.3em 1em;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #b8b8b8;
      font-size: 13px;
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #b8b8b8;
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #b8b8b8;
      opacity: 1;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #b8b8b8;
      opacity: 1;
    }
  }
  &__col {
    display: flex;
    font-size: 12px;
    padding: 0 25px;
    justify-content: space-between;
    text-transform: uppercase;
    @media only screen and (max-width: 600px) {
      padding: 0 15px;
    }
  }
  &__select-all {
    border: 1px solid #d7dfe1;
    border-radius: 5px;
    padding: 1px 5px;
    color: var(--primarydark2);
    background-color: #f6f8fb;
    font-size: 0.95em;
    font-weight: 500;
    letter-spacing: 0.05em;
    &:hover {
      border: 1px solid #aaaab9;
    }
    label {
      cursor: pointer;
    }
    input {
      display: none;
    }
  }
  &__select-filtered {
    border: 1px solid #d7dfe1;
    border-radius: 5px;
    padding: 1px 5px;
    color: var(--primarydark2);
    background-color: #f6f8fb;
    font-size: 0.95em;
    font-weight: 500;
    letter-spacing: 0.05em;
    &:hover {
      border: 1px solid #aaaab9;
    }
    label {
      cursor: pointer;
    }
    input {
      display: none;
    }
  }
  &__filters-wrapp {
    margin-top: 20px;
    height: 157px;
    overflow-y: auto;
  }

  &__check-wrapp {
    padding: 8px 25px;
    margin-bottom: 0px;
    border-top: 1px solid #f2f2f9;
    position: relative;
    @media only screen and (max-width: 600px) {
      padding: 0 15px;
    }
    input[type='checkbox'] {
      display: none;

      & + label {
        position: relative;
        cursor: pointer;
        font-family: 'Manrope', sans-serif;
        font-size: 13px;
        line-height: 22px;
        padding-left: 30px;
        display: inline-block;
        border-radius: 5px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: padding 0.25s ease;
        &:after {
          // border: solid 2px #000;
          border: solid 2px var(--primarycolor);
          border-radius: 5px;
          content: '';
          width: 18px;
          height: 18px;
          opacity: 0.7;
          top: 2px;
          left: 0;
          position: absolute;
        }
        &:before {
          width: 14px;
          height: 14px;
          content: '';
          position: absolute;
          top: 0px;
          left: 4px;
          // background-color: #000;
          // background-color: var(--primarycolor);
          background-color: #fff;
          opacity: 0;
          will-change: transform;
          transform: scale(0.5);
          transition: all 0.2s ease;
        }
        &:hover {
          padding-left: 32px;
        }
      }
      &:checked {
        & + label {
          &:before {
            margin: 1px 0px 0px 0px;
            opacity: 0.7;
            color: var(--primarycolor);
            font-size: 12px;
            font-family: FontAwesome;
            content: '\f00c';
            transform: scale(1);
          }
        }
      }
    }
  }
}

@keyframes load {
  0% {
    left: -200px;
    width: 20%;
  }
  50% {
    width: 40%;
  }
  70% {
    width: 60%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  100% {
    left: 100%;
  }
}

.link {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px;
  z-index: 9999;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
  }
  .fa {
    font-size: 28px;
    margin-right: 8px;
    color: #fff;
  }
}
</style>
