var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"bSelfServeDropdown",staticClass:"form-input-wrap",class:{ disabled: _vm.isDisabled || _vm.readonly },on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[(!_vm.activated && !_vm.multiple)?_c('div',{staticClass:"row middle-xs between-xs form-click",class:{ disabled: _vm.isDisabled || _vm.readonly },attrs:{"tabindex":_vm.tabindex},on:{"click":_vm.onFirstClick,"focus":_vm.onFirstFocus}},[_c('div',{staticClass:"selected-text"},[_vm._v(" "+_vm._s(_vm.initialText)+" ")]),_c('div',{staticClass:"action-icons"},[(_vm.showClearSelectionButton)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'times']},on:{"click":function($event){$event.stopPropagation();return _vm.onClearSelection.apply(null, arguments)}}}):_vm._e(),_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-down']}})],1)]):_c('div',{class:[{ focused: _vm.isFocused || _vm.autofocus }, { filled: _vm.isFilled }, 'form-input form-select']},[_c('v-select',{ref:"fancySelect",class:[
        {
          required: _vm.showRequired,
          'multiple-input-field': _vm.multiple,
          'safari-browser': _vm.isSafari(),
        },
        'form-input-field',
        `${_vm.className}`,
      ],attrs:{"options":_vm.selectOptions,"disabled":_vm.isDisabled || _vm.readonly,"placeholder":_vm.labelText,"label":_vm.labelKey,"multiple":_vm.multiple,"clear-search-on-select":_vm.clearSearchOnSelect,"close-on-select":!_vm.multiple,"value":_vm.internalValue,"searchable":_vm.searchable || _vm.multiple,"clearable":false,"tabindex":_vm.tabindex,"loading":_vm.loading,"selectable":_vm.selectableOptions,"append-to-body":"","calculate-position":_vm.withPopper,"prepend-icon":"edit"},on:{"search:focus":_vm.onSearchFocus,"search:blur":_vm.onSearchBlur,"input":_vm.onInput},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"vs__dropdown-hover"},[_c('span',{on:{"mousedown":(ev) => _vm.onClickParentOption(ev, option)}},[_c('div',{staticClass:"optionContainer"},[_c('font-awesome-icon',{staticClass:"itemCheckbox unchecked",attrs:{"icon":['fas', 'check']}}),_c('span',{staticClass:"optionItem"},[_vm._v(_vm._s(option[_vm.labelKey]))])],1)]),(_vm.hasNestedOpts(option))?_c('ul',{staticClass:"vs__dropdown-menu vs__dropdown-submenu"},_vm._l((option[_vm.optsKey]),function(opt,index){return _c('li',{key:index,staticClass:"vs__dropdown-option",class:{
                'vs__dropdown-option--selected': _vm.selected(opt),
                'vs__dropdown-option--disabled': _vm.selected(opt),
              },attrs:{"id":`vs_option-${opt[_vm.idKey]}`,"role":"option"},on:{"mousedown":function($event){$event.stopPropagation();return _vm.onClickNestedOption(opt)}}},[_vm._v(" "+_vm._s(opt[_vm.labelKey])+" ")])}),0):_vm._e()])]}},(!_vm.multiple)?{key:"selected-option",fn:function(){return [_vm._v(_vm._s(_vm.initialText))]},proxy:true}:null,(_vm.create)?{key:"no-options",fn:function({ search, searching }){return [(searching)?[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.createNewOption(search)}}},[_vm._v(_vm._s(search)+" (Create New)")])]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Start typing.")])]}}:null],null,true)},[_c('div',{staticClass:"row middle-xs action-icons",attrs:{"slot":"open-indicator"},slot:"open-indicator"},[(_vm.showClearSelectionButton && _vm.showClearOption)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'times']},on:{"click":function($event){$event.stopPropagation();return _vm.onClearSelection.apply(null, arguments)}}}):_vm._e(),_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-down']}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }