<template>
  <div class="bdc_main_wrap whero">
    <div class="thw_content">
      <div class="internal-sub-nav">
        <div
          v-for="(tab, i) in tabOptions"
          :key="i"
          class="isn-item"
          :class="{ active: tab.id === selectedTab, disabled: tab.disabled }"
          @click="tabOptions.length === 1 ? null : onTabClick(tab)"
        >
          {{ tab.value }}
        </div>
      </div>
    </div>
    <div v-if="tableauJwtToken" class="bdc_top_panel_wrap">
      <tableau-viz
        id="tableauViz"
        :src="tableauViewURL"
        :token="tableauJwtToken"
        toolbar="top"
        hide-tabs
      >
        <custom-parameter name=":showShareOptions" value="false"></custom-parameter>
        <viz-filter field="Advertiser" :value="advertiserId"> </viz-filter>
        <viz-filter field="Start Date" :value="startDate"></viz-filter>
        <viz-filter field="End Date" :value="endDate"></viz-filter>
        <viz-filter field="Days To Convert Parameter" :value="updateConversionWindow"></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Media Type"
          :value="selectedMediaTypeOptions"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Campaign"
          :value="campaignOptionsValues"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="AdReady ID"
          :value="selectedAudienceOptions"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Creative ID"
          :value="creativeOptionsOptions"
        ></viz-filter>
        <viz-filter field=":refresh" value="yes"></viz-filter>
        <viz-parameter field="embed" value="yes"></viz-parameter>
      </tableau-viz>
    </div>
  </div>
</template>
<script>
import _ from 'underscore';
import { get } from 'vuex-pathify';
import axios from 'axios';
import {
  MNI_REPORTING_TABLEAU_URL,
  DEFAULT_CONVERSION_WINDOW,
  MNI_OVERVIEW_TAB_OPTIONS,
  MNI_ADVERTISER_TEMPLATE_MAPPING,
} from '~/constant';
import { formatDateForAPI } from '~/util/apiDateFormat';
import config from '~/config';
import { isDemoInstance } from '~/util/utility-functions';

export default {
  data() {
    const tabOptions = MNI_OVERVIEW_TAB_OPTIONS;
    let selectedTab = '';
    let selectedTabTitle = '';
    let selectedTabUrl = '';
    let selectedTabSubUrl = '';
    if (tabOptions?.length) {
      const defaultSelectedTab = tabOptions.find((t) => t.selected) || this.tabOptions[0];
      selectedTab = defaultSelectedTab?.id || tabOptions[0].id;
      selectedTabTitle = defaultSelectedTab?.value || tabOptions[0].value;
      if (isDemoInstance()) {
        selectedTabUrl = defaultSelectedTab?.demoViewUrl || tabOptions[0].demoViewUrl;
        selectedTabSubUrl = defaultSelectedTab?.demoViewSubUrl || tabOptions[0].demoViewSubUrl;
      } else {
        selectedTabUrl = defaultSelectedTab?.viewUrl || tabOptions[0].viewUrl;
        selectedTabSubUrl = defaultSelectedTab?.viewSubUrl || tabOptions[0].viewSubUrl;
      }
    }
    return {
      tabOptions,
      selectedTab,
      selectedTabTitle,
      selectedTabUrl,
      selectedTabSubUrl,
      MNI_REPORTING_TABLEAU_URL,
      tableauJwtToken: null,
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    dates: get('dashboard/dates'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    filtersApplied: get('dashboard/filtersApplied'),
    audienceOptions: get('dashboard/filters@audienceOptions'),
    creativeOptions: get('dashboard/filters@creativeOptions'),

    advertiserId() {
      return this.advertiser ? this.advertiser.xandrAdvertiserId : 0;
    },
    startDate() {
      return formatDateForAPI(this.dates.startDate);
    },
    endDate() {
      return formatDateForAPI(this.dates.endDate);
    },
    tableauViewURL() {
      const selectedTemplate = MNI_ADVERTISER_TEMPLATE_MAPPING.find(
        (t) => t.advertiserId === this.advertiser.id
      );
      const selectedTemplateId = selectedTemplate.templateId;
      const templateURL = `${config.TABLEAU_URL}${this.selectedTabUrl}`;
      let tableauViewURL = '';
      if (this.selectedTab === 'journeyanalytics') {
        tableauViewURL = `${templateURL}`;
      } else {
        tableauViewURL = `${templateURL}-${selectedTemplateId}${this.selectedTabSubUrl}-${selectedTemplateId}`;
      }
      return tableauViewURL;
    },
    // below code return empty string when all items are selected sending empty string considered as all selected in tablue.
    selectedMediaTypeOptions() {
      const allChecked = this.mediaTypeOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      return this.mediaTypeOptions
        .filter((item) => item.checked === true)
        .map((item) => item.value)
        .join(',');
    },
    campaignOptionsValues() {
      const allChecked = this.campaignOptions.every((f) => f?.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      const result = this.campaignOptions.filter((f) => f?.checked);
      const campaignIds =
        result
          .map((res) => res.value)
          .filter((fres) => fres)
          .join(',') || '';

      return campaignIds;
    },
    selectedAudienceOptions() {
      const allChecked = this.audienceOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      const selectedAudience = this.audienceOptions
        .filter((item) => item.checked === true)
        .map((item) => item.key)
        .join(',');

      return selectedAudience;
    },
    updateConversionWindow() {
      return this.conversionWindow ? this.conversionWindow : DEFAULT_CONVERSION_WINDOW;
    },
    creativeOptionsOptions() {
      const allChecked = this.creativeOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      const selectedCreative = this.creativeOptions
        .filter((item) => item.checked === true)
        .map((item) => item.key)
        .join(',');

      return selectedCreative;
    },
  },
  watch: {
    advertiserId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    accountId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    dates: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    mediaTypeOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    conversionWindow: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    campaignOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    audienceOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    filtersApplied: {
      deep: true,
      async handler(n) {
        if (n) {
          this.$store.set('dashboard/filtersApplied', false);
          this.$store.set('dashboard/filtersAppliedLoading', false);
          this.debounceTableauAuthToken();
        }
      },
    },
  },
  async mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      `${config.TABLEAU_URL}/javascripts/api/tableau.embedding.3.latest.js`
    );
    recaptchaScript.setAttribute('type', 'module');
    document.head.appendChild(recaptchaScript);
    this.getTableauAuthToken();
  },
  methods: {
    debounceTableauAuthToken() {
      _.debounce(this.getTableauAuthToken(), 100, true);
    },
    async getTableauAuthToken() {
      axios
        .get(`${config.ADREADY_URL}/api/token/tableau`, {
          withCredentials: true,
        })
        .then((d) => {
          this.tableauJwtToken = d.data.result;
        });
    },
    onTabClick(tab) {
      if (tab.disabled) {
        return;
      }
      this.selectedTab = tab.id;
      this.selectedTabTitle = tab.value;
      if (isDemoInstance()) {
        this.selectedTabUrl = tab.demoViewUrl;
        this.selectedTabSubUrl = tab.demoViewSubUrl;
      } else {
        this.selectedTabUrl = tab.viewUrl;
        this.selectedTabSubUrl = tab.viewSubUrl;
      }
      this.$store.set('dashboard/selectedDashboardTab', tab.id);

      this.debounceTableauAuthToken();
    },
  },
};
</script>
<style lang="scss" scoped>
.bdc_main_wrap.whero {
  /* margin-top: 70px; */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.body-content.fullmax .thw_content {
  max-width: 1600px;
}
.thw_content {
  max-width: 1270px;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0px;
  position: relative;
  z-index: 11;
}
.sopened .thw_content {
  max-width: 1160px;
}
.internal-sub-nav {
  display: inline-block;
  float: none;
  margin: 11px 20px 31px 5px;
  display: block;
}
.isn-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarydark2);
  position: relative;
  cursor: pointer;
}

.isn-item.active {
  color: var(--primarydark);
}

.isn-item.active::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarycolor);
}

.isn-item:hover {
  color: var(--primarydark);
}

.isn-item:hover::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarydark2);
}
@media (max-width: 750px) {
  .bdc_top_panel_wrap {
    flex-wrap: wrap !important;
  }
}
.bdc_top_panel_wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 3;
}
</style>
