<template>
  <div class="iframe-wrapper" style="background-color: #fbfcfe;">
    <iframe allow="clipboard-read; clipboard-write" :src="embedUrl" class="emb-iframe" />
  </div>
</template>
<script>
import { get } from 'vuex-pathify';
import appConfig from '~/config';
import { isDemoInstance } from '~/util/utility-functions';
import { KEY_DEMO_ACCOUNT_ID, KEY_DEMO_ADVERTISER_ID } from '~/constant';

export default {
  props: {
    dashboard: {
      type: Object,
      required: false,
      default: () => {},
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    filterCacheId: get('common/filterCacheId'),
    currentUserId: get('common/currentUserId'),
    embedUrl() {
      if (!this.dashboard) {
        return '';
      }

      const { embedUrlType } = this.dashboard;
      let url = '';

      if (embedUrlType === 'flip') {
        url = this.flipEmbedURL('');
      } else if (embedUrlType === 'flip_report') {
        url = this.flipEmbedURL('/report_builder');
      } else if (embedUrlType === 'flip_admin_settings') {
        url = this.flipEmbedURL('/admin_settings');
      }

      return url !== '' ? encodeURI(url) : '';
    },
  },
  methods: {
    flipEmbedURL(route) {
      let accountId = this.account?.id;
      let advertiserId = this.advertiser?.id;
      if (isDemoInstance()) {
        accountId = parseInt(localStorage.getItem(KEY_DEMO_ACCOUNT_ID), 10);
        advertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
      }
      let cacheId = this.filterCacheId;
      if (!cacheId) {
        cacheId = Math.random()
          .toString()
          .substr(2, 6);
        this.$store.dispatch('common/setFilterCacheId', cacheId);
      }
      return `${appConfig.FLIP_URL}/#${route}/?embed=true&accountId=${accountId}
      &advertiserId=${advertiserId}&embedTitle=${this.title}&accountName=${this.account.name}
      &advertiserName=${this.advertiser.name ? this.advertiser.name : ''}
      &xandrAdvertiserId=${
        this.advertiser.xandrAdvertiserId ? this.advertiser.xandrAdvertiserId : ''
      }&currentUserId=${this.currentUserId}&filterCacheId=${cacheId}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.iframe-wrapper {
  z-index: 0;
  margin-top: 0px;
  margin-left: 50px;
  border-top: 3px solid var(--primarycolor);
}
.emb-iframe {
  width: 100%;
  height: 100vh;
  border: 0px;
}
</style>
