<template>
  <div>
    <div class="prog-wrap2">
      <div class="progress">
        <div class="barOverflow">
          <div
            class="bar"
            :style="{
              transform: 'rotate(' + rotateCircleEstimate + 'deg)',
            }"
          ></div>
        </div>
        <span class="pace2 light-grey">{{ displayPacingEstimate }}</span>
        <div
          class="pace-dif light-grey"
          :class="showDiff ? 'show-dif' : ''"
          :style="{
            color: paceDescColor,
          }"
        >
          {{ `${paceDiff ? `${Math.abs(paceDiff)}%` : 'On Pace'}` }} {{ paceDiffDesc }}
        </div>
      </div>
    </div>

    <div class="prog-wrap">
      <div class="progress">
        <div class="barOverflow">
          <div
            class="bar"
            :style="{
              transform: 'rotate(' + rotateCircle + 'deg)',
              borderBottomColor: barColor,
              borderRightColor: barColor,
            }"
          ></div>
        </div>
        <div class="pace">
          <span class="light-grey">{{ displayPacing }}</span>
          <div class="light-grey" style="display: inline-block;">%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PacingChart',
  props: {
    pacing: {
      type: Number,
      required: true,
      default: () => 0,
    },
    rowData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      displayPacing: 0,
      displayPacingEstimate: 0,
      rotateCircle: 45,
      rotateCircleEstimate: 45,
      barColor: this.getProgressBarColor(),
      paceDescColor: this.getProgressBarColor(),
      showDiff: false,
    };
  },
  computed: {
    startDate() {
      return new Date(new Date(this.rowData.startDate).toISOString().slice(0, 10));
    },
    endDate() {
      return new Date(new Date(this.rowData.endDate).toISOString().slice(0, 10));
    },
    budget() {
      return this.rowData.budget;
    },
    currentDate() {
      return new Date(new Date().toISOString().slice(0, 10));
    },
    totalDateDiff() {
      return Math.abs(this.endDate - this.startDate) / 1000 / 60 / 60 / 24;
    },
    currentDateDiff() {
      return Math.abs(this.currentDate - this.startDate) / 1000 / 60 / 60 / 24;
    },
    effectiveDateDiff() {
      let effectiveDateDiff = this.currentDateDiff;
      if (this.currentDateDiff > this.totalDateDiff) effectiveDateDiff = this.totalDateDiff;
      return effectiveDateDiff;
    },
    pacingEstimate() {
      return Math.round((this.effectiveDateDiff / this.totalDateDiff) * 100);
    },
    paceDiff() {
      return this.pacing - this.pacingEstimate;
    },
    paceDiffDesc() {
      let paceDiffDesc = '';
      if (this.paceDiff > 0) paceDiffDesc = 'Over';
      if (this.paceDiff < 0) paceDiffDesc = 'Under';
      return paceDiffDesc;
    },
  },
  watch: {
    pacing() {
      this.change();
      this.changeEstimate();
      this.getProgressBarColor();
    },
    showDiff() {
      this.change();
      this.changeEstimate();
      this.getProgressBarColor();
    },
  },
  created() {
    this.change();
    this.changeEstimate();
    this.getProgressBarColor();
  },
  methods: {
    change() {
      if (this.displayPacing === this.pacing) {
        return;
      }
      if (this.displayPacing < this.pacing) {
        this.displayPacing++;
        if (this.displayPacing <= 100) {
          this.rotateCircle = 45 + this.displayPacing * 1.8;
        }
      }
      setTimeout(() => this.change(), 20);
      setTimeout(() => this.updateShowDiff(), 1000);
    },
    changeEstimate() {
      if (this.displayPacingEstimate === this.pacingEstimate) {
        return;
      }
      if (this.displayPacingEstimate < this.pacingEstimate) {
        this.displayPacingEstimate++;
        if (this.displayPacingEstimate <= 100) {
          this.rotateCircleEstimate = 45 + this.displayPacingEstimate * 1.8;
        }
      }

      setTimeout(() => this.changeEstimate(), 20);
      setTimeout(() => this.updateShowDiff(), 1000);
    },
    getProgressBarColor() {
      // *** LOGIC FOR COLOR CHANGE BASED ON PACING ROUND OFF VALUE ***

      const paceDiff = Math.abs(this.paceDiff);

      // red
      if (paceDiff >= 20) {
        this.barColor = '#d74047';
        this.paceDescColor = '#d74047';
        return '#d74047';
      }
      // yellow
      if (paceDiff >= 10 && paceDiff < 20) {
        this.barColor = '#fdce07';
        this.paceDescColor = '#cab246';
        return '#fdce07';
      }
      // blue
      this.barColor = '#54a8c7';
      this.paceDescColor = '#54a8c7';
      return '#54a8c7';
    },
    updateShowDiff() {
      this.showDiff = true;
    },
  },
};
</script>

<style lang="scss" scoped>
/*Progress arc styles*/

.progress {
  position: relative;
  margin: 4px;
  float: left;
  text-align: center;
  top: 15px;
  margin-right: -43px;
}
/* Wraps the rotating .bar */
.barOverflow {
  position: relative;
  overflow: hidden;
  width: 74px;
  height: 30px;
  margin-bottom: -17px;
  margin-top: -28px;
  left: 5px;
  margin-left: -10px;
}
.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 6px solid rgba(0, 0, 0, 0.07);
  border-bottom-color: #54a8c7;
  border-right-color: #54a8c7;
  transform: rotate(45deg);
}

.bar2,
.red {
  border-bottom-color: #d74047;
  border-right-color: #d74047;
}
.bar3,
.yellow {
  border-bottom-color: #fdce07;
  border-right-color: #fdce07;
}
.rotate45x {
  transform: rotate(45deg);
}

.prog-wrap {
  width: 57px;
  margin: auto;
  transform: scale(0.83);
}

.pace {
  margin-left: -10px;
}

.prog-wrap2 {
  position: absolute;
  margin: -2px 0px 0px 0px;
  transform: scale(0.9);
  z-index: 2;
}

.prog-wrap2 .bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 10px solid rgba(0, 0, 0, 0);
  border-bottom-color: transparent;
  border-right-color: transparent;
  margin: 0px 0px 0px 10px;
  transform: rotate(45deg);
}

.prog-wrap2 .bar::after {
  /* this is the black line to show Expected Pacing */
  content: '';
  width: 1px;
  height: 10px;
  background-color: #000;
  display: inline-block;
  margin: 33px 0px 10px -35px;
  transform: rotate(45deg);
}
.prog-wrap2 span {
  opacity: 0;
}
.pace-dif {
  white-space: nowrap;
  position: absolute;
  display: inline-block;
  width: 70px;
  text-align: center;
  line-height: 1em;
  margin: 20px 0px 0px -35px;
  color: #54a9c8;
  opacity: 0;
  transform: scale(1);
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.pace-dif.show-dif {
  opacity: 1;
}
.tg .tg-0lax {
  position: relative; /* since wrapping the absolute positioned tb-status so scrolls with page */
  z-index: 1; /* to stay under sticky table header */
}
</style>
