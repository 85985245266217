<template>
  <div class="bdc_main_wrap whero">
    <div v-if="selectedReport !== null">
      <div class="action-icons">
        <div></div>
        <div class="action-icons-left">
          <!-- // to add more icons copy this below div and paste at the end of this div -->
          <div class="ftop-icons">
            <div class="ttip-wrap"><div class="ttip">Agency Reports</div></div>
            <div @click="handleBackOption">
              <font-awesome-icon
                :icon="['far', 'folder-closed']"
                class="fa-regular fa-folder-closed btn-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="thw_content">
      <div class="internal-sub-nav">
        <div class="isn-item active">Custom Reports</div>
      </div>
    </div> -->
    <my-report-builder
      v-if="selectedReport === null"
      section-title="Agency Reports"
      :meta-data="rptMetaData"
      :rows="views"
      :sort-by="sortBy"
      :sort-direction="sortDirection"
      :loading="filtersAppliedLoading"
      @view-report="handleViewReportClick"
      @select-report="handleSelectReport"
      @on-sort-direction-change="handleSortDirectionChange"
      @on-change-sort-by="handleSortByChange"
    />
    <report-info-container
      v-if="selectedReport !== null"
      :report-info="selectedReport"
      :account-level="true"
    />
  </div>
</template>
<script>
import { get } from 'vuex-pathify';
import MyReportBuilder from '~/components/my-report-builder.vue';
import appConsoleDataLayer from '~/api/app-console-data';
import ReportInfoContainer from './report-info-container.vue';

export default {
  name: 'AGENCYREPORTS',

  components: { MyReportBuilder, ReportInfoContainer },
  data() {
    return {
      sortBy: 'createdAt',
      sortDirection: 'desc',
      selectedReport: null,
      filtersAppliedLoading: true,
      rptMetaData: [
        {
          key: 'name',
          value: 'Report Name',
          align: 'left',
          formatter: {},
          allowSorting: true,
        },
        {
          key: 'createdAt',
          value: 'Date Created',
          align: 'left',
          formatter: { type: 'datetime', format: 'M/D/YYYY' },
          allowSorting: true,
        },
        {
          key: 'actions',
          value: 'Actions',
          align: 'right',
          allowSorting: false,
        },
      ],
    };
  },
  computed: {
    account: get('common/account'),
  },
  asyncComputed: {
    views: {
      default: [],
      async get() {
        return this.loadViews();
      },
    },
  },
  methods: {
    loadViews() {
      return appConsoleDataLayer.fetchMyAccountReports(this.account?.id).then((response) => {
        this.filtersAppliedLoading = false;
        return response;
      });
    },
    handleViewReportClick() {
      console.log('handleViewReportClick');
    },
    handleSelectReport(row) {
      this.selectedReport = row;
    },
    handleBackOption() {
      this.selectedReport = null;
    },
    handleSortDirectionChange(direction) {
      this.sortDirection = direction;
    },
    handleSortByChange(value) {
      this.sortBy = value;
    },
  },
};
</script>
<style lang="scss">
.def-box {
  padding: 20px 4px 10px 25px;
  max-height: 354px;
  z-index: 10;
  position: relative;
}
.expan {
  &.expd {
    height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
  }
}
</style>
<style lang="scss" scoped>
#performanceAnalysis {
  scroll-margin: 75px;
}

.bdc_main_wrap.whero {
  /* margin-top: 70px; */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.body-content.fullmax .thw_content {
  max-width: 1600px;
}
.thw_content {
  max-width: 1270px;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0px;
  position: relative;
  z-index: 11;
}
.sopened .thw_content {
  max-width: 1160px;
}
.internal-sub-nav {
  display: inline-block;
  float: none;
  margin: 11px 20px 31px 5px;
  display: block;
}
.isn-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarydark2);
  position: relative;
  cursor: pointer;
}

.isn-item.active {
  color: var(--primarydark);
}

.isn-item.active::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarycolor);
}

.isn-item:hover {
  color: var(--primarydark);
}

.isn-item:hover::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarydark2);
}

@media (max-width: 900px) {
  .bdc_top_panel_wrap {
    flex-wrap: wrap !important;
  }
}
.bdc_top_panel_wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 3;
}

::v-deep.bdc_top_panel_wrap .pnl_itm_title_txt {
  padding-bottom: 2px;
  white-space: nowrap;
}

.bdc_panel {
  padding: 19px 25px 14px 25px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(0, 40, 60, 0.07);
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgb(165 173 178 / 22%);
}
@media (max-width: 900px) {
  .bdc_panel.two-thirds {
    width: 100% !important;
  }
}

.bdc_panel.two-thirds {
  width: calc(65% - 15px);
  display: inline-block;
}
@media (max-width: 900px) {
  .bdc_panel.one-thirds {
    width: 100% !important;
  }
}
.bdc_panel.one-thirds {
  width: calc(35% - 15px);
  display: inline-block;
}

.bdc_panel.last {
  float: right;
}

.bdc_panel .dropdown {
  border: 1px solid #c8d3e4;
}
.action-icons {
  display: flex;
  justify-content: space-between; /* This will push items in the action-icons-left to the left and others to the right */
  align-items: center; /* This will align items vertically center */
}

.ftop-icons {
  position: relative;
  vertical-align: top;
  color: var(--primarydark2);
  cursor: pointer;
  font-size: 16px;
  margin-right: 3px;
  margin-left: 4px;

  .ttip-wrap {
    position: absolute;
    width: max-content;
    text-align: center;
    transform: translate3d(0px, 6px, 0px); /* Start below the icon */
    padding-top: 0px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    font-size: 14px;
    transition: all 0.2s ease;
    z-index: 103;
    pointer-events: none;
    left: 50%; /* Center horizontally relative to the parent */
    top: -30px; /* Position the tooltip above the icon */
    transform: translateX(-50%) translateY(6px); /* Center horizontally and start below */
    .ttip {
      display: inline-block;
      position: relative;
      padding: 4px 8px;
      border-radius: 6px;
      background-color: var(--primarydark2);
      color: #fff;
      text-align: center;
      font-size: 0.8em;
      font-weight: 500;
      line-height: 1.1em;
      margin-top: 0px;
    }

    .ttip::before {
      content: '';
      display: inline-block;
      left: 50%;
      top: 100%; /* Position arrow at the bottom of the tooltip */
      height: 0px;
      width: 0px;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
      border-top: solid 6px var(--primarydark2); /* Change to border-top to point upwards */
      position: absolute;
      transform: translateX(-50%);
    }
  }

  &:hover .fa-folder-closed {
    color: var(--primarycolor);
  }

  &:hover .ttip-wrap {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0px); /* Move tooltip upwards on hover */
  }
}

.action-icons-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn-icon {
  margin-left: auto;
}
</style>
