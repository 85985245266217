<template>
  <!-- @click.stop here is a hack to prevent clicks in the dropdown propagating up any further -->
  <div
    ref="bSelfServeDropdown"
    class="form-input-wrap"
    :class="{ disabled: isDisabled || readonly }"
    @click.stop="() => {}"
  >
    <!-- Click to activate dropdown shim -->
    <div
      v-if="!activated && !multiple"
      class="row middle-xs between-xs form-click"
      :class="{ disabled: isDisabled || readonly }"
      :tabindex="tabindex"
      @click="onFirstClick"
      @focus="onFirstFocus"
    >
      <div class="selected-text">
        {{ initialText }}
      </div>
      <div class="action-icons">
        <font-awesome-icon
          v-if="showClearSelectionButton"
          :icon="['fal', 'times']"
          @click.stop="onClearSelection"
        />
        <font-awesome-icon :icon="['fal', 'chevron-down']" />
      </div>
    </div>

    <!-- v-select -->
    <div
      v-else
      :class="[{ focused: isFocused || autofocus }, { filled: isFilled }, 'form-input form-select']"
    >
      <v-select
        ref="fancySelect"
        :options="selectOptions"
        :disabled="isDisabled || readonly"
        :placeholder="labelText"
        :label="labelKey"
        :multiple="multiple"
        :clear-search-on-select="clearSearchOnSelect"
        :close-on-select="!multiple"
        :value="internalValue"
        :class="[
          {
            required: showRequired,
            'multiple-input-field': multiple,
            'safari-browser': isSafari(),
          },
          'form-input-field',
          `${className}`,
        ]"
        :searchable="searchable || multiple"
        :clearable="false"
        :tabindex="tabindex"
        :loading="loading"
        :selectable="selectableOptions"
        append-to-body
        :calculate-position="withPopper"
        prepend-icon="edit"
        @search:focus="onSearchFocus"
        @search:blur="onSearchBlur"
        @input="onInput"
      >
        <div slot="open-indicator" class="row middle-xs action-icons">
          <font-awesome-icon
            v-if="showClearSelectionButton && showClearOption"
            :icon="['fal', 'times']"
            @click.stop="onClearSelection"
          />
          <font-awesome-icon :icon="['fal', 'chevron-down']" />
        </div>

        <template #option="option">
          <div class="vs__dropdown-hover">
            <!-- Main option menu (first level of dropdown) -->
            <span @mousedown="(ev) => onClickParentOption(ev, option)">
              {{ option[labelKey] }}
            </span>

            <!-- submenu for nested options (second level of dropdown) -->
            <ul v-if="hasNestedOpts(option)" class="vs__dropdown-menu vs__dropdown-submenu">
              <li
                v-for="(opt, index) in option[optsKey]"
                :id="`vs_option-${opt[idKey]}`"
                :key="index"
                role="option"
                class="vs__dropdown-option"
                :class="{
                  'vs__dropdown-option--selected': selected(opt),
                  'vs__dropdown-option--disabled': selected(opt),
                }"
                @mousedown.stop="onClickNestedOption(opt)"
              >
                {{ opt[labelKey] }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Show currently selected option value -->
        <!-- The following slot is only really needed for the case where we have
        nested options. Currently we don't have a prop directly tied to enabled
        nesting, however we don't currently require BOTH multiselect & nesting,
        so this should be safe enough to disable the slot. -->
        <template v-if="!multiple" #selected-option>{{ initialText }}</template>
        <template v-if="create" #no-options="{ search, searching }">
          <template v-if="searching">
            <span style="cursor: pointer" @click="createNewOption(search)"
              >{{ search }} (Create New)</span
            >
          </template>
          <em v-else style="opacity: 0.5">Start typing.</em>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import $ from 'jquery';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { createPopper } from '@popperjs/core';
import { isBlank } from 'adready-api/helpers/common';
import elementsMixin from '../mixins/element-mixin';
import validationsMixin from '../mixins/validation-mixin';
import domHelpers from '../../helpers/dom-helpers';

export default {
  name: 'BAutoCompleteDropdown',

  components: {
    vSelect,
  },

  mixins: [validationsMixin, elementsMixin],

  props: {
    name: {
      required: false,
      type: String,
      default: null,
    },
    value: {
      required: false,
      type: [String, Number, Array, Object, Boolean],
      default: null,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
    options: {
      required: true,
      type: Array,
    },
    defaultEmpty: {
      required: false,
      type: Boolean,
      default: true,
    },
    searchable: {
      required: false,
      type: Boolean,
      default: false,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    // prop name used for displaying option text when options is an array of
    // objects
    labelKey: {
      required: false,
      type: String,
      default: 'name',
    },
    // When set, use this key for overriding the text which is displayed for the selected option.
    labelSelectedKey: {
      required: false,
      type: String,
      default: null,
    },
    // prop name used for setting/emitting the selected value when options is an
    // array of objects
    idKey: {
      required: false,
      type: String,
      default: 'id',
    },
    // prop name used for field containing nested options when options is an
    // array of objects.
    optsKey: {
      required: false,
      type: String,
      default: 'opts',
    },
    clearSearchOnSelect: {
      required: false,
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      required: false,
      type: Boolean,
      default: false,
    },
    showClearButton: {
      required: false,
      type: Boolean,
      default: true,
    },
    showClearOption: {
      required: false,
      type: Boolean,
      default: true,
    },
    autofocus: {
      required: false,
      type: Boolean,
      default: undefined,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    showClearSelectionBtn: {
      required: false,
      type: Boolean,
      default: false,
    },
    create: {
      required: false,
      type: Boolean,
      default: false,
    },
    nonSelectableOptions: {
      required: false,
      type: Array,
      default: () => [],
    },
    className: {
      required: true,
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      internalValue: null,
      tempVal: null, // used when options are getting updated
      isFocused: false,
      scrollableParent: undefined,
      activated: false,
      hoverValue: '',
    };
  },

  computed: {
    // Text which is displayed before the user clicks to activate
    initialText() {
      // if we have a selected option
      if (this.internalValue) {
        const v = this.internalValue;
        if (_.isObject(v)) {
          if (!isBlank(this.labelSelectedKey) && !isBlank(v[this.labelSelectedKey])) {
            return v[this.labelSelectedKey];
          }
          return v[this.labelKey];
        }
        return v.toString();
      }
      if (!isBlank(this.labelText)) {
        return this.labelText;
      }
      return null;
    },

    isDisabled() {
      if (this.disabled) {
        return true;
      }
      if (this.create) {
        return false;
      }
      return false;
      // return isBlank(this.selectOptions);
    },

    /**
     * Make sure we always work with an array
     */
    selectOptions() {
      if (Array.isArray(this.options)) {
        return this.options;
      }
      return [];
    },

    /**
     * Returns true if `options` is an array of Objects
     */
    haveObjectOptions() {
      return !_.isEmpty(this.selectOptions) && _.isObject(this.selectOptions[0]);
    },

    /**
     * Returns true if a value is set, i.e., some option is selected, either
     * programatically at start or by user.
     */
    isFilled() {
      return (
        // multi-select has an array with values
        (this.multiple && Array.isArray(this.internalValue) && !_.isEmpty(this.internalValue)) ||
        // or just not null/undefined for single-select
        !isBlank(this.internalValue)
      );
    },

    // whether or not the field should be marked as required
    isRequired() {
      return (
        this.required &&
        this.selectOptions.length > 0 &&
        (this.multiple ? !(this.internalValue && this.internalValue.length > 0) : true)
      );
    },

    showClearSelectionButton() {
      return !this.multiple && !_.isEmpty(this.internalValue) && this.showClearSelectionBtn;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.setInternalValue(this.value);
        });
      },
    },

    internalValue: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (!this.multiple || !this.$el) {
            return;
          }
          $(this.$el)
            .find('.vs__deselect')
            .attr('tabindex', -1);
        });
      },
    },

    isRequired() {
      this.$nextTick(() => {
        // Since we mark the field as required based on some extra logic, we
        // need to revalidate whenever this flag changes. Specifically, since we
        // load options dynamically, often after the form element is loaded,
        // this flag will trigger the html5 constraint validation to run.
        this.validate();
      });
    },

    options: {
      immediate: true,
      handler() {
        // Force the select value to get set properly when the options list is
        // changing dynamically.
        //
        // This is a workaround for a race-condition where the value is actually
        // set before the options are loaded (via API), and the value never
        // becomes effective in the display. So we clear the value and then one
        // tick later, set it back.
        this.$nextTick(() => {
          this.tempVal = this.internalValue;
          this.internalValue = null;
          this.$nextTick(() => {
            // TODO: if the value does not exist in the current set of options, null it out?
            this.setInternalValue(this.tempVal);
            this.tempVal = null;
          });
        });
      },
    },
  },

  created() {
    this.onScroll = _.debounce(this.onScroll, 250, true);
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        if (this.$refs.fancySelect && this.$refs.fancySelect.$refs.search) {
          this.$refs.fancySelect.$refs.search.focus();
        }
      });
    }
    setTimeout(() => {
      window.addEventListener('click', this.onClickOutsideEvent);
      window.addEventListener('keydown', this.onClickOutsideEventKey);
    }, 0);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutsideEvent);
    window.removeEventListener('keydown', this.onClickOutsideEventKey);
  },

  methods: {
    agentHas(keyword) {
      return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
    },
    isSafari() {
      return (
        (!!window.ApplePaySetupFeature || !!window.safari) &&
        this.agentHas('Safari') &&
        !this.agentHas('Chrome') &&
        !this.agentHas('CriOS')
      );
    },
    createNewOption(searchText) {
      this.$refs.fancySelect.open = false;
      this.$emit('onClickCreateNew', searchText);
    },

    selected(option) {
      return this.$refs.fancySelect.isOptionSelected(option);
    },

    selectableOptions(option) {
      return !this.nonSelectableOptions.some((o) => o.id === option.id);
    },

    setInternalValue(val) {
      this.internalValue = val;
      if (this.haveObjectOptions && !this.multiple && !_.isObject(val)) {
        // search for val in the list of options when not using multiselect
        // const opt = this.selectOptions.find((o) => o[this.idKey] === val);
        const opt = this.findOptionById(this.selectOptions, val);
        if (opt) {
          this.internalValue = opt;
        }
      }
      this.$nextTick(() => {
        this.validate();
      });
    },

    /**
     * Recursively search the given stack for our needle.
     *
     * This is to support nested options and multilevel menus.
     */
    findOptionById(stack, needle) {
      for (let i = 0; i < stack.length; i++) {
        const o = stack[i];
        if (o[this.idKey] === needle) {
          return o;
        }
        const opts = o[this.optsKey];
        if (opts && opts.length > 0) {
          const f = this.findOptionById(opts, needle);
          if (f) {
            return f;
          }
        }
      }
      return undefined;
    },

    /**
     * Previously we relied on HTML5 constraint validations to check for
     * required values, however the move to using only custom dropdowns with
     * vue-select means we need to handle this validation ourselves.
     *
     * Currently only checks if we have a value and fires a validation event
     * accordingly.
     */
    validate() {
      if (!this.isRequired) {
        this.updateError('required', null);
      } else {
        const v = this.isFilled ? null : 'Please select a value';
        this.updateError('required', v);
      }
    },

    /**
     * Helper for testing if the given option has nested options.
     *
     * Note: for some reason, this did not work when inline in the v-if
     * condition, but this does. Bug in vue? Weird <template> slot behavior? Not
     * sure.
     */
    hasNestedOpts(option) {
      return option[this.optsKey] && option[this.optsKey].length > 0;
    },

    /**
     * Called from v-select directly to popsition the dropdown menu
     */
    withPopper(dropdownList, component) {
      // set dropdown size hints
      const css = {
        // set minimum to size of text input field. can grow for longer option text
        'min-width': `${$(this.$refs.fancySelect.$refs.toggle).width()}px`,
      };
      if (this.selectOptions.length >= 9) {
        // constrain height when lots of options
        css['max-height'] = '300px';
        css.overflow = 'auto';
      }
      $(this.$refs.fancySelect.$refs.dropdownMenu).css(css);

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom-start',
      });
      return () => popper.destroy();
    },

    // events

    // Close the dropdown when scrolling horizontally. Fires only when the
    // dropdown is open.
    onScroll() {
      this.$refs.fancySelect.closeSearchOptions();
    },

    /**
     * Custom positioning of submenu dropdown
     */

    /**
     * Custom positioning of dropdown, to ensure it works in all scenarios.
     * Specifically, in modals and other areas (like line item table) which have
     * a vertical overflow w/ scrollbars.
     *
     * We still want the entire dropdown to show outside of the overflow area,
     * so we must move it to the body and absolutely position it.
     */
    onSearchFocus() {
      this.$nextTick(() => {
        if (this.scrollableParent === undefined) {
          // only if undefined. can be null
          this.scrollableParent = domHelpers.getScrollParent(this.$el);
        }
        if (this.scrollableParent) {
          this.scrollableParent.addEventListener('scroll', this.onScroll);
        }
      });
    },

    onSearchBlur() {
      this.hoverValue = '';
      if (this.scrollableParent) {
        this.scrollableParent.removeEventListener('scroll', this.onScroll);
      }
    },

    onClearSelection() {
      this.setInternalValue(this.multiple ? [] : null);
      this.$emit('input', this.internalValue);
      this.$emit('change', this.internalValue);
    },

    onInput(values) {
      setTimeout(() => {
        this.$emit('search:blur');
        this.setInternalValue(values);

        // emit here instead of in the setter because we only want to emit when
        // the user makes an input
        //
        // if single-select, make sure to return only the id/value and not the whole object
        let v;
        if (this.multiple || !this.haveObjectOptions) {
          // either we have multiple values (in which case we expect objects) or not dealing with objects
          v = values;
        } else if (values && values !== null) {
          v = values[this.idKey];
        }
        this.$emit('input', v);
        this.$emit('change', v);
        this.retainFocus();
      }, 100);
    },

    searchText() {
      this.$emit('searchTextAdv', this.input.value);
    },

    onFirstFocus() {
      if (this.tabindex >= 0) {
        this.onFirstClick();
      }
    },

    onFirstClick() {
      if (this.isDisabled) {
        return;
      }
      this.activated = true;
      this.$forceNextTick(() => {
        if (this.$refs.fancySelect) {
          this.$refs.fancySelect.searchEl.focus();
        } else {
          console.warn("kselect not found, can't focus");
        }
      });
    },

    /**
     * Handle option selection while filtering out clicks on nested option groups.
     */
    onClickParentOption(ev, option) {
      if (this.hasNestedOpts(option)) {
        // cancel the default event when we have nested options
        // i.e., don't let v-select fire our onInput() event
        ev.stopPropagation();
      }
    },

    /**
     * Need to manually fire onInput with the correct opttion (passed in from
     * v-for) because v-select does not by default support nested options. If we
     * allow it to fire normally, it will give us the parent option value, which
     * is not what we want.
     */
    onClickNestedOption(opt) {
      if (!this.selected(opt)) {
        // fire onInput event when selection changes
        this.onInput(opt);
      }
    },
    onClickOutsideEvent(event) {
      const el = this.$refs.bSelfServeDropdown;
      if (el && !(el === event.target || el.contains(event.target))) {
        const selectElement = document.getElementsByClassName(this.className)[0];
        if (selectElement) {
          selectElement.getElementsByClassName('vs__search')[0].placeholder = `${this.labelText} ${
            this.internalValue?.length > 0 && this.required ? '*' : ''
          }`;
        }
      }
    },
    onClickOutsideEventKey(event) {
      if (event.key === 'Escape') {
        if (!(this.$el === event.target || this.$el.contains(event.target))) {
          const selectElement = document.getElementsByClassName(this.className)[0];
          if (selectElement) {
            selectElement.getElementsByClassName('vs__search')[0].placeholder = `${
              this.labelText
            } ${this.internalValue?.length > 0 && this.required ? '*' : ''}`;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';

::v-deep .vs__search {
  max-width: 300px;
  width: 100%;
  padding: 0px 4px;
  border: 0;
  display: inline-block;
}
::v-deep input::placeholder {
  -webkit-text-security: none;
  color: rgb(117, 117, 117);
  direction: inherit !important;
  pointer-events: none !important;
  text-orientation: inherit !important;
  writing-mode: inherit !important;
  font-size: 13px;
  font-family: 'Manrope';
  font-weight: 500;
}
::v-deep .safari-browser input::placeholder {
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: hidden;
  overflow-y: hidden;
  line-height: initial;
  -webkit-text-security: none;
  direction: inherit;
  pointer-events: none;
  text-orientation: inherit;
  writing-mode: inherit;
  font-size: 11px !important;
  font-family: -apple-system !important;
  color: darkgray !important;
  font-weight: 400;
}

::v-deep .vs__selected {
  background: var(--primarycolor) !important;
  color: #fff !important;
  font-family: 'Manrope';
  font-weight: 600;
  font-size: 13px !important;
  border-radius: 5px;
  display: inline-block;
  border: unset;
  opacity: 1;
  max-width: 543px;
}
::v-deep .vs__selected button {
  appearance: none;
  margin-left: 2px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: rgba(60, 60, 60, 0.5);
  text-shadow: 0 1px 0 #fff;
  padding-right: 5px;
  top: 0.5px;
  position: relative;
}
::v-deep .vs__selected button svg {
  stroke: var(--primarycolor);
  stroke-width: 1px;
  color: unset;
  fill: #fff;
}
body .vs__dropdown-hover span {
  color: var(--primarydark2);
  font-size: 14px;
  padding: 8px 20px;
  text-wrap: wrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
}
ß .form-select {
  .form-input-field {
    position: relative;
    appearance: none;
    &:focus {
      &:invalid {
        color: $grey_3;
      }
    }
  }
}

select[disabled] {
  cursor: auto;
  background-color: $white;
}
</style>

<style lang="scss">
body .vs__dropdown-menu {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  background: rgb(248 248 250 / 98%) !important;
  position: relative;
  border-radius: 0px 0px 6px 6px;
  width: 570px;
}
body .vs__dropdown-menu .vs__dropdown-options {
  color: var(--primarydark2);
  font-size: 14px;
  padding: 8px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
}
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--selected {
  display: none;
}
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
  background: rgb(88 88 125 / 6%);
}
body
  .vs__dropdown-menu
  .vs__dropdown-option.vs__dropdown-option--highlight
  .vs__dropdown-hover
  span {
  color: var(--primarydark);
  text-wrap: wrap;
}
.form-input {
  .form-input-field {
    &.v-select {
      .vs__dropdown-toggle {
        background-color: #fff;
        border: 1px solid #c5d2d8;
        border-radius: 6px;
      }
      &.vs--open {
        .vs__dropdown-toggle {
          border-radius: 6px 6px 0px 0px;
        }
      }
    }
  }
}
/* stylelint-disable selector-class-pattern */
ul.vs__dropdown-menu {
  z-index: 99999999;
}

/* FIXME: couldn't get scoped styles to work as we have more specific styles
that are global? */
.form-input-wrap {
  .form-click {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 42px;
    max-height: inherit;
    padding: 0;
    margin: 0;
    border: none;

    &.row {
      flex-wrap: nowrap;
    }

    .selected-text {
      padding: 0 0 0 10px;
      // margin: 4px 0 4px;
      // font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      line-height: 1.4;
      color: inherit;
      // text-align: left;
      // flex-basis: 82%;
    }

    &.disabled {
      color: #adadad;
      pointer-events: none;
      background-color: transparent;
    }

    .action-icons {
      padding: 0px 11px 0 0;

      .fa-times {
        position: absolute;
        left: -8px;
        display: none;
        width: 14px;
        margin: 2px 0 0 0;
        font-size: 0.9em;
        color: $grey_4;
        background-color: #f5eeee;
        border-radius: 2px;

        &:hover {
          color: #fff;
          background-color: $grey_3;
        }
      }

      svg {
        margin: 0;
        font-size: 0.8em;
      }
    }

    &:hover {
      .fa-times {
        display: block;
      }
    }
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
